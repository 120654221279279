import React from 'react'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { Banner, Breadcrumb, Title } from 'components'

export default function HomeInterna() {
  return (
    <>
      <Banner />
      <Title title="Area riservata" />
      {/* <Breadcrumb current="Homepage" /> */}
      <Typography variant="h2">
        Benvenuto/a nell’area riservata esclusivamente al personale sanitario
      </Typography>
      <br />
      <Link to={'eventi'} style={{ textDecoration: 'none' }}>
        <Typography variant="h4">- Eventi</Typography>
      </Link>
      <br />
      <Link to={'documenti'} style={{ textDecoration: 'none' }}>
        <Typography variant="h4">
          - Materiale informativo scientifico
        </Typography>
      </Link>
    </>
  )
}
