import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ApolloError, useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  Typography,
  TextField,
  useTheme,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core'
import { LOGIN } from 'mutation'
import { decode } from 'jsonwebtoken'
import { isLoggedInVar, roleVar, userIdVar } from 'cache'
import {
  loginVariables,
  login as loginType,
  UserRole,
} from 'types/generated/schemaTypes'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: '0 auto',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      padding: '27px 40px',
    },
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  submit: {
    margin: theme.spacing(0, 0, 3),
  },
  invalidEmail: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    textAlign: 'left',
    padding: theme.spacing(1),
  },
  text: {
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: 405,
    },
  },
}))

interface LoginFormValues {
  email: string
  password: string
}

const formatError = (err: ApolloError): string => {
  return err.graphQLErrors[0].message
}

interface LoginFormProps {
  onRequestAccess: () => void
  onResetPassword: () => void
}

const LoginForm: React.FC<LoginFormProps> = ({
  onRequestAccess,
  onResetPassword,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const [showPassword, setShowPassword] = useState(false)
  const enableElevation = useMediaQuery(theme.breakpoints.up('md')) ? 1 : 0
  const formik = useFormik<LoginFormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Indirizzo email non valido')
        .required('Campo obbligatorio'),
      password: Yup.string().required('Campo obbligatorio'),
    }),
    onSubmit: async (values) => {
      await login({
        variables: { email: values.email, password: values.password },
      })
    },
  })

  const [login, { error: loginError, loading: isLoggingIn }] = useMutation<
    loginType,
    loginVariables
  >(LOGIN, {
    onCompleted: (data) => {
      const token = data.login.token
      localStorage.setItem('b_token', token)
      const { userId, role } = decode(token) as {
        userId: string
        role: string
      }
      localStorage.setItem('b_role', role)
      localStorage.setItem('b_userId', userId)
      roleVar(role as UserRole)
      isLoggedInVar(true)
      userIdVar(userId)
      // Redirect in base al ruolo
      switch (role) {
        case 'PROFESSIONAL':
          history.push(`/eventi`)
          // history.push(`/home-interna`)
          break
        default:
          history.push(`/gestione-eventi`)
      }
    },
    onError: (err) => {
      formik.resetForm()
    },
  })

  return (
    <Paper className={classes.paper} elevation={enableElevation}>
      <>
        <Typography align="center" variant="h3" style={{ marginBottom: 0 }}>
          Log-in area riservata
        </Typography>
        <Typography
          className={classes.text}
          align="center"
          variant="caption"
          color="textSecondary"
          component="p"
        >
          Per informazioni e/o assistenza{' '}
          <a href="mailto:areariservata@bromatech.it">
            areariservata@bromatech.it
          </a>
        </Typography>
        <Divider style={{ margin: '15px auto 30px' }} />

        <Typography
          align="center"
          variant="h3"
          style={{ fontWeight: 700, marginBottom: 0 }}
        >
          Non hai un account:{' '}
          <Link
            underline="always"
            onClick={onRequestAccess}
            style={{ cursor: 'pointer' }}
          >
            richiedi l'accesso
          </Link>
        </Typography>

        <Divider style={{ margin: '20px auto 20px' }} />

        <Typography
          align="center"
          variant="h3"
          style={{ fontWeight: 700, marginBottom: 20 }}
        >
          Hai già un account:{' '}
          <span style={{ color: theme.palette.primary.main }}>
            inserisci le credenziali di seguito
          </span>
        </Typography>

        <form
          onSubmit={formik.handleSubmit}
          className={classes.form}
          noValidate
        >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={12} lg={6}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  name="email"
                  error={formik.touched.email && !!formik.errors.email}
                  helperText={
                    formik.touched.email &&
                    !!formik.errors.email &&
                    formik.errors.email
                  }
                  variant="outlined"
                  id="email"
                  size="small"
                  label="Email*"
                  value={formik.values.email}
                  onChange={(e) =>
                    formik.setFieldValue('email', e.target.value.trim())
                  }
                  disabled={isLoggingIn}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={6}>
              <FormControl fullWidth={true} variant="outlined" size="small">
                <InputLabel htmlFor="password">Password*</InputLabel>
                <OutlinedInput
                  name="password"
                  error={formik.touched.password && !!formik.errors.password}
                  id="password"
                  label="Password*"
                  value={formik.values.password}
                  onChange={(e) =>
                    formik.setFieldValue('password', e.target.value.trim())
                  }
                  disabled={formik.isSubmitting}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText
                  error={formik.touched.password && !!formik.errors.password}
                  variant="standard"
                >
                  {formik.touched.password &&
                    !!formik.errors.password &&
                    formik.errors.password}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          {loginError && (
            <p className={classes.invalidEmail}>{formatError(loginError)}</p>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
            size="large"
            disabled={isLoggingIn}
            className={classes.submit}
          >
            Accedi
          </Button>
          <Typography variant="body1">
            <Link
              underline="always"
              onClick={onResetPassword}
              style={{ cursor: 'pointer' }}
            >
              Recupera password
            </Link>
          </Typography>
        </form>
      </>
    </Paper>
  )
}

export default LoginForm
