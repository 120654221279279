import React from 'react'
import { Grid } from '@material-ui/core'
// import { SizeMe } from 'react-sizeme'
import { useParams } from 'react-router-dom'
import PDFViewer from 'pdf-viewer-reactjs'
import { GET_DOCUMENT } from 'query'
import { useQuery } from '@apollo/client'
import { getDocument, getDocumentVariables } from 'types/generated/schemaTypes'
import { Breadcrumb, Title } from 'components'

export default function Documento() {
  const { id } = useParams<{ id: string }>()
  const {
    data: documentData,
    loading: documentLoading,
    error: documentError,
  } = useQuery<getDocument, getDocumentVariables>(GET_DOCUMENT, {
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
  })

  return (
    <>
      <Title title="Documento" />
      <Breadcrumb current="Documenti scientifici" />
      <div>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12}>
            {documentLoading && <div>Caricamento del documento in corso</div>}
            {!documentLoading && !documentError && documentData && (
              <PDFViewer
                withCredentials={process.env.NODE_ENV === 'production'}
                scaleStep={1}
                document={{
                  url: documentData.getDocument?.url,
                }}
                navbarOnTop={false}
                css="pdf-viewer-wrapper"
                // canvasCss="pdf-viewer"
              />
            )}
          </Grid>
        </Grid>
      </div>
    </>
  )
}
